<template>
  <div class="tag-index page-center-content">
    <!-- banner -->
    <div class="banner">
      <recommend-banner :list="recommendArticles"></recommend-banner>
    </div>
    <!-- content -->
    <div class="list-content">
      <div class="list-content-left" v-infinite-scroll="getArtiles" :infinite-scroll-immediate=false
        :infinite-scroll-distance=30 :infinite-scroll-disabled="!loadMore">
        <div class="list-header">
          <div class="list-header-left">
            <el-button round size="small" :class="{ 'active': tabType === 'recommend' }"
              @click="changeTab('recommend')">{{ $t('recommend') }}</el-button>
            <el-button :class="{ 'active': tabType === 'community' }" round size="small"
              @click="changeTab('community')">{{ $t('menuCommunity') }}</el-button>
          </div>
          <div class="list-header-right">
            <tag :tag="classification"></tag>
          </div>
        </div>
        <div>
          <div class="list-community-header" v-if="tabType === 'community'">
            <ul class="post-top-area" v-if="topCommunityPost.length > 0">
              <li class="cursor-pointer" v-for="item in topCommunityPost" :key="'com' + item.id"
                @click="$router.push('/post/' + item.id)">
                <span>TOP</span>
                <div>{{ item.title }}</div>
              </li>
            </ul>
            <div class="order-tab">
              <div :class="{ 'active': communityOrderType === 'HOT' }" @click="communityOrderType = 'HOT'">
                {{ $t('hoting') }}</div>
              <div :class="{ 'active': communityOrderType === 'NEW' }" @click="communityOrderType = 'NEW'">
                {{ $t('startFire') }}</div>
            </div>
          </div>
          <div v-for="item in articleList" :key="'tag' + item.id">
            <feed-list-common v-if="item.type === 'POST'" :item="item"></feed-list-common>
            <feed-list-rich v-else :item="item"></feed-list-rich>
          </div>
        </div>
      </div>
      <div class="list-content-right" v-if="labels && labels.length > 0">
        <div class="sticky-top-area" :style="{ position: fixed ? 'fixed' : 'static' }">
          <div class="side-bar-item">
            <div class="bar-item-title">{{ $t('aboutLabels') }}</div>
            <div class="bar-tag-list">
              <el-tag v-for="(label, index) in labels" :key="'tag' + label.id + index" size="small">{{
        label.name
                }}</el-tag>
            </div>
          </div>
        </div>
      </div>
    </div>

    <publish-post-btn></publish-post-btn>
  </div>
</template>
<script>
import FeedListCommon from '../components/FeedListCommon.vue'
import FeedListRich from '../components/FeedListRich.vue'
import RecommendBanner from '../components/RecommendBanner.vue'
import Tag from '../components/Tag.vue'
import PublishPostBtn from '../components/PublishPostBtn.vue'
import { articleApi } from '@/utils/api'
export default {
  components: { Tag, FeedListCommon, FeedListRich, RecommendBanner, PublishPostBtn },
  name: 'index',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    classification () {
      return this.$store.state.classifications.find(item => item.id.toString() === this.id)
    },
    labels () {
      const classifications = this.$store.state.classifications
      if (classifications && classifications.length > 0) {
        const ls = classifications.find(item => item.id.toString() === this.id)
        if (ls && ls.labels.length > 0) {
          return ls.labels
        }
      }
      return null
    }
  },
  watch: {
    '$route.params.id': function (newVal, oldVal) {
      this.id = newVal
      this.currentPage = 1
      this.fetchData()
    },
    tabType: function (newVal, oldVal) {
      this.currentPage = 1
      this.articleList = []
      this.getArtiles()
    },
    communityOrderType: function (newVal, oldVal) {
      this.currentPage = 1
      this.articleList = []
      this.getArtiles()
    }
  },
  data () {
    return {
      fixed: false,
      id: this.$route.params.id,
      isloading: false,
      loadMore: true,
      currentPage: 1,
      articleList: [],
      tabType: 'recommend',
      recommendArticles: [],
      topCommunityPost: [], // 社区置顶帖子
      communityOrderType: 'HOT' // 社区排序类型
    }
  },
  created () {
    // 监听window对象的scroll事件
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted () {
    this.fetchData()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll: function () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      this.fixed = scrollTop > 400
    },
    changeTab: function (type) {
      this.tabType = type
      if (type === 'recommend') {
        this.getRecommends()
      }
    },
    fetchData: function () {
      this.getRecommends()
      this.getArtiles()
    },
    getRecommends: function () {
      const params = {
        items: [
          {
            articleSpecialType: 'TOP',
            num: 4
          }
        ]
      }
      articleApi.getSpecileArticles(params).then(resp => {
        if (resp.httpStatus === 200 && resp.data) {
          for (let i = 0; i < resp.data.length; i++) {
            if (resp.data[i].specialType === 'TOP') {
              this.recommendArticles = resp.data.find(item => item.specialType === 'TOP').articles
              this.topCommunityPost = resp.data.find(item => item.specialType === 'TOP').articles
            }
            console.log('tags recommend', this.recommendArticles)
          }
        }
      })
    },
    /**
     * 文章列表
     */
    getArtiles: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      articleApi.getArticleListByClassificationId({
        classificationId: this.id,
        pageSize: 20,
        currentPage: this.currentPage,
        articleType: this.tabType === 'recommend' ? 'CONSULTATION' : 'POST'
      }).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200 && resp.data) {
          this.currentPage++
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          this.articleList = this.articleList.concat(resp.data)
          console.log('article list', this.articleList)
          const articleIds = this.articleList.map(item => item.id)
          this.getArticleInteractions(articleIds.join(','))
        }
      }).catch(err => {
        console.log('err', err)
        this.isloading = false
      })
    },
    /**
     *  文章互动数据
     * @param {文章 id} articleIds 用逗号分割
     */
    getArticleInteractions: function (articleIds) {
      if (!articleIds || articleIds.length === 0) {
        return
      }
      articleApi.getArticleInteractions({
        articleIds: articleIds
      }).then(resp => {
        if (resp.httpStatus === 200 && resp.data) {
          this.articleInteractions = resp.data
          // 为 articles 追加 互动数据
          const interactionMap = new Map(this.articleInteractions.map(interaction => [interaction.articleId, interaction]))
          this.articleList = this.articleList.map(article => {
            const interactionData = interactionMap.get(article.id)
            if (interactionData) {
              // 如果找到互动数据，则追加 likeNum 和 commentNum
              return {
                ...article,
                likeNum: interactionData.likeNum,
                commentNum: interactionData.commentNum
              }
            } else {
              // 如果没有找到互动数据，则保留原有文章数据
              return article
            }
          })
          console.log('article interactions', this.articleInteractions)
        }
      })
    }
  }
}
</script>

<style scoped>
.tag-index {
  padding-top: 30px;
  padding-bottom: 100px;
}

.banner {
  height: 325px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.banner-left {
  width: 570px;
  height: 100%;
  background: red;
  border-radius: 14px;
  overflow: hidden;
  color: white;
  padding: 20px;
}

.banner-right {
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.banner-right-top {
  width: 100%;
  height: 176px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.right-top-item {
  width: 244px;
  height: 100%;
  border-radius: 14px;
  overflow: hidden;
  background: wheat;
}

.banner-right-bottom {
  width: 100%;
  height: 139px;
  background: green;
  border-radius: 14px;
  overflow: hidden;
}

.list-content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.list-content-left {
  width: 778px;
  min-height: 1300px;
  overflow-y: auto;
}

.list-content-right {
  width: 282px;
  min-height: 300px;
}

.list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.list-header-right {
  display: flex;
  align-items: center;
}

.list-header-left>>>.el-button {
  padding: 7px 20px;
}

.list-header-left .active {
  background-color: #0580FF;
  border-color: #0580FF;
  color: white;
}

.side-bar-item {
  background-color: white;
  border-radius: 14px;
  margin-bottom: 20px;
  padding: 20px;
}

.side-bar-item .bar-item-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgb(50, 50, 50);
}

.bar-tag-list {
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
}

.bar-tag-list>>>.el-tag {
  margin: 4px;
  cursor: pointer;
}

.list-community-header .order-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  font-size: 16px;
  column-gap: 10px;
}

.list-community-header .order-tab>div.active {
  font-weight: 700;
  text-align: center;
  color: rgb(59, 130, 246);
}

.list-community-header .order-tab>div {
  cursor: pointer;
  position: relative;
}

.list-community-header .order-tab>div.active:after {
  content: "";
  width: 50px;
  height: 2px;
  left: 7px;
  border-color: rgb(59, 130, 246);
  background-color: rgb(59, 130, 246);
  border-bottom-width: 1px;
  display: block;
  position: absolute;
  bottom: -10px;
}

.list-community-header .post-top-area {
  background-color: rgb(255, 255, 255);
  border-radius: 14px;
  display: grid;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.list-community-header .post-top-area>li {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  color: rgb(82, 82, 82);
  position: relative;
  cursor: pointer;
}

.list-community-header .post-top-area>li>:not([hidden])~:not([hidden]) {
  margin-left: 8px;
}

.list-community-header .post-top-area>li:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgb(242, 242, 242);
}

.list-community-header .post-top-area>li>span {
  font-style: italic;
  background: red;
  color: white;
  font-size: 12px;
  line-height: 16px;
}
</style>
